<template>
    <strong class="error_message body2">
        <font-awesome-icon class="error_message__icon" :icon="['fas', 'exclamation-triangle']" />
        <slot></slot>
    </strong>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faExclamationTriangle);

export default {
    name: "ErrorMessage",
    components: {
        FontAwesomeIcon,
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
.error_message {
    color: $red;
    display: block;
    background: rgba($red, 0.05);
    padding: 24px 16px;
}
</style>
