<template>
    <div class="inbox_company_revise">
        <header class="inbox_company_revise__header">
            <h4 class="inbox_company_revise__header_title">
                {{ $t("cp__inbox__revise__title") }}
            </h4>
            <p class="body2">
                {{ $t("cp__inbox__revise__intro_text") }}
                {{ $t("cp__inbox__revise__note_text", { replaceTxt: storeReviseLink }) }}
            </p>
        </header>
        <Editor
            class="editor"
            @focus="editorActive = true"
            @blur="editorActive = false"
            :value="storeReviseText"
            @input="inputHandler"
            :init="editorConfig"
        >
        </Editor>
        <footer class="inbox_company_revise__footer">
            <ErrorMessage
                class="inbox_company_revise__error"
                v-for="(error, index) in errors.messages"
                :key="`login-error-${index}`"
            >
                {{ error }}
            </ErrorMessage>
            <LoaderCircular v-if="review.requestReviseSubmitting" />
            <div v-else-if="!inviteHasBeenSent" class="inbox_company_revise__footer__buttons">
                <Button @click.native="cancel" buttonStyle="white">
                    {{ $t("cp__general__cancel") }}
                </Button>
                <Button
                    @click.native="submit"
                    class="inbox_company_revise__footer__send_invitation_button"
                >
                    <EventListener v-if="!editorActive" @keyup.enter="submit" />
                    {{ $t("cp__inbox__revise__submit_button") }}
                </Button>
            </div>
            <div v-else class="inbox_company_revise__success_label">
                <font-awesome-icon
                    class="inbox_company_revise__success_icon"
                    :icon="['fas', 'check-circle']"
                />
                {{ $t("cp__inbox__revise__success_text") }}
            </div>
        </footer>
    </div>
</template>

<script>
// eslint-disable-next-line
import tinymce from "tinymce/tinymce";
import "tinymce/themes/modern/theme";
import "tinymce/plugins/lists";
import "tinymce/plugins/table";
import "tinymce/plugins/image";
import "tinymce/skins/lightgray/skin.min.css";
import Editor from "@tinymce/tinymce-vue";
import { vueWindowSizeMixin } from "vue-window-size";
import { mapActions, mapState, mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import LoaderCircular from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/LoaderCircular.vue";
import ErrorMessage from "@/components/Global/Atoms/ErrorMessage.vue";
import EventListener from "@/components/Global/Atoms/EventListener.vue";

library.add(faCheckCircle);

export default {
    name: "InboxCompanyRevise",
    mixins: [vueWindowSizeMixin],
    components: {
        Button,
        LoaderCircular,
        ErrorMessage,
        FontAwesomeIcon,
        EventListener,
        Editor,
    },
    props: {
        ID: {
            required: true,
        },
    },
    data() {
        return {
            inviteHasBeenSent: false,
            reviseText: "",
            editorActive: false,
            editorConfig: {
                height: 300,
                plugins: ["lists", "image", "table"],
                branding: false,
                statusbar: false,
                menubar: false,
                toolbar:
                    "undo redo | styleselect | fontsizeselect | bold italic | bullist numlist | table | image ",
                forced_root_block: false,
                entity_encoding: "raw",
                file_picker_types: "image",
                image_description: false,
                image_dimensions: false,
                file_picker_callback: (cb) => {
                    const input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.setAttribute(
                        "accept",
                        "image/*image/jpg, image/gif, image/png, image/jpeg"
                    );
                    input.addEventListener("change", (e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener("load", () => {
                            // eslint-disable-next-line
                            const id = "blobid" + new Date().getTime();
                            // eslint-disable-next-line
                            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                            const base64 = reader.result.split(",")[1];
                            const blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);
                            /* call the callback and populate the Title field with the file name */
                            cb(blobInfo.blobUri(), { title: file.name });
                        });
                        reader.readAsDataURL(file);
                    });

                    input.click();
                },
                language: "en",
            },
        };
    },
    computed: {
        ...mapState({
            storeReviseTextObject: (state) => state.UserConfig.reviseText,
        }),
        ...mapGetters({
            getReviewByID: "CompanyReviewStore/getReviewByID",
        }),
        review() {
            return this.getReviewByID(this.ID);
        },
        errors() {
            return this.review.requestReviseErrors || {};
        },
        storeReviseText() {
            let reviseText = "";
            if (this.storeReviseTextObject) {
                reviseText = this.storeReviseTextObject.reviseMailText;
            }
            return reviseText;
        },
        storeReviseLink() {
            return this.storeReviseTextObject ? this.storeReviseTextObject.reviseMailLink : "";
        },
        windowSize() {
            return this.windowWidth + this.windowHeight;
        },
    },
    watch: {
        windowSize() {
            this.setEditorHeight();
        },
        storeReviseText() {
            this.reviseText = this.storeReviseText;
        },
    },
    methods: {
        ...mapActions({
            getReviseText: "UserConfig/getReviseText",
            requestRevise: "CompanyReviewStore/requestRevise",
        }),
        submit() {
            this.requestRevise({ ID: this.ID, reviseText: this.reviseText }).then(() => {
                this.inviteHasBeenSent = true;
            });
        },
        cancel() {
            this.$emit("cancel");
        },
        inputHandler(reviseText) {
            this.reviseText = reviseText;
        },
        setEditorHeight() {
            const subtraction = this.windowWidth < 1000 ? 272 : 216;
            const root = document.documentElement;
            const height = this.windowHeight * 0.6 - subtraction;
            root.style.setProperty("--editor-height", `${height}px`);
        },
    },
    mounted() {
        this.setEditorHeight();
        this.getReviseText();
        this.reviseText = this.storeReviseText;
    },
};
</script>

<style lang="scss">
.ck-editor__editable:not(.ck-editor__nested-editable) {
    height: var(--editor-height);
}
</style>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.inbox_company_revise {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 900px) {
        height: calc(100vh - 32px);
    }
}
.inbox_company_revise__footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 40px;
    @media screen and (max-width: 900px) {
        padding-bottom: 16px;
    }
    .inbox_company_revise__footer__buttons {
        display: flex;
        .inbox_company_revise__footer__send_invitation_button {
            margin-left: 16px;
        }
    }
}
.inbox_company_revise__success_label {
    @extend %body2_style;
    height: 56px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
}
.inbox_company_revise__success_icon {
    margin-right: 8px;
    color: $green;
    font-size: 22px;
}
.inbox_company_revise__header_title {
    @extend %headline5_style;
    font-weight: bold;
    padding-bottom: 30px;
}
</style>
